// Constants
import { ADDONS } from '@/constants'
// Components
import CardContainer from '@/components/ui/CardContainer'
import CardContainerElement from '@/components/ui/CardContainerElement'
import FormButtons from '@/components/ui/FormButtons'
import GoogleMapAutocomplete from '@/components/ui/GoogleMapAutocomplete'
import GoogleMapLoader from '@/components/ui/GoogleMapLoader'
import GoogleMapMarker from '@/components/ui/GoogleMapMarker'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required, url } from 'vuelidate/lib/validators'
// Vuex
import { mapGetters, mapActions } from 'vuex'
// utils
import { get, cloneDeep, isNil, isEmpty } from 'lodash'
// SSNN
const everySSNN = ['instagram', 'facebook', 'twitter', 'whatsapp', 'pinterest', 'tripadvisor']
// Formato Telefono
import { PhoneNumberUtil } from 'google-libphonenumber'

const phoneUtil = PhoneNumberUtil.getInstance()

export default {
  name: 'ContactConfigPlace',
  components: {
    CardContainer,
    CardContainerElement,
    FormButtons,
    GoogleMapLoader,
    GoogleMapMarker,
    GoogleMapAutocomplete,
    VuetifyToolBar
  },
  mixins: [addonsMixin, formMixin, uiMixin, validationMixin],
  data() {
    // Campos a validar de SSNN
    const formFieldsSSNN = everySSNN.reduce((sumSSNN, SSNN) => {
      sumSSNN[SSNN] = null
      return sumSSNN
    }, {})
    // Texto de validación de los campos de SSNN
    const formFieldsValidationsSSNN = everySSNN.reduce((sumSSNN, SSNN) => {
      sumSSNN[SSNN] =
        SSNN === 'whatsapp'
          ? { numberFormat: 'Formato incorrecto de teléfono' }
          : { url: 'La url no es correcta' }
      return sumSSNN
    }, {})

    return {
      // Form
      formFields: {
        email: {
          required: 'Campo obligatorio'
        },
        phone1: null,
        phone2: null,
        place: null,
        extraAddress: null,
        // everySSNN
        ...formFieldsSSNN
      },
      formFieldsValidations: {
        phone1: {
          required: 'Campo obligatorio'
        },
        place: {
          required: 'Debe seleccionar una de las direcciones sugeridas'
        },
        ...formFieldsValidationsSSNN
      },
      // Others
      placeMarker: null // used to draw the marker in the map
    }
  },
  computed: {
    ...mapGetters('place', ['placeData'])
  },
  watch: {
    'formFields.place'(value) {
      if (value) {
        // Redibujamos la marca en el mapa
        this.setMarkerInMap(value)
      }
    }
  },
  mounted() {
    this.getEveryNeededData()
  },
  methods: {
    ...mapActions('place', ['updateConfigs']),
    /**
     * Show possibles errors
     *
     * @param {string} error - error to show
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },
    /**
     * Obtenemos y seteamos todos los elementos necesarios
     * para la carga inicial del componente
     */
    getEveryNeededData() {
      // Inicializa datos
      this.setFormFieldsValues()
    },
    /**
     * Set inital values in formulary field to set the validation correctly
     */
    setFormFieldsValues() {
      const contactConfig = get(this.placeAddonsSetupByUser, ADDONS.contact, {})

      this.formFields.email = contactConfig.email || null
      this.formFields.extraAddress = contactConfig.extraAddress || null
      this.formFields.phone1 = contactConfig.phone1 || this.placeData.phone1
      this.formFields.phone2 = contactConfig.phone2 || null
      this.formFields.place = isNil(contactConfig.place)
        ? cloneDeep(this.placeData.place)
        : cloneDeep(contactConfig.place)

      // Set markers in map
      if (contactConfig.place) {
        // Corrige problema con la carga del mapa
        // hay que darle una vuelta a esto
        // TODO
        setTimeout(() => {
          this.setMarkerInMap(contactConfig.place)
        }, 500)
      }

      // SSNN
      if (contactConfig.ssnn) {
        // Recorremos todas las redes sociales y se asigna en el estado del form
        everySSNN.forEach((SSNN) => {
          const currentSSNN = contactConfig.ssnn.find((currentSSNN) => {
            return currentSSNN.id === SSNN
          })

          if (currentSSNN) {
            this.formFields[SSNN] = currentSSNN.url
          }
        })
      }
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      // Variables de SSNN
      const formFieldsSSNN = []
      // Resto de variables
      const formFieldsOthers = {}

      // Parsemos los datos
      Object.entries(this.formFields).forEach((field) => {
        everySSNN.indexOf(field[0]) > -1
          ? formFieldsSSNN.push({
              id: field[0],
              url: field[1]
            })
          : (formFieldsOthers[field[0]] = field[1])
      })

      // Update configs
      await this.updateConfigs({
        id: this.placeData.id,
        addOns: [
          {
            id: ADDONS.contact,
            configFields: {
              ...formFieldsOthers,
              ssnn: formFieldsSSNN
            }
          }
        ]
      })

      // show info
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })
    },
    /**
     * Set marker to show in map
     *
     * @param {Object} place - place, address
     */
    setMarkerInMap(place) {
      this.placeMarker = {
        title: place.locality,
        label: place.locality,
        position: {
          lat: place.latitude,
          lng: place.longitude
        }
      }

      // Posicionamos el mapa en el centro de la búsqueda
      if (this.$refs.googleMapLoader) {
        this.$refs.googleMapLoader.setCenterMap({
          lat: place.latitude,
          lng: place.longitude
        })
      }
    }
  },
  // Validations with Vuelidate
  validations() {
    // Validación de número de teléfono
    const numberFormat = (value) => {
      // Si valor es vacío lo damos por buenos
      if (isNil(value) || isEmpty(value)) return true

      const countryCode = get(this.formFields, 'place.country_short', 'ES')
      try {
        const parsedPhoneNumber = phoneUtil.parse(value, countryCode)
        return phoneUtil.isValidNumberForRegion(parsedPhoneNumber, countryCode)
      } catch (error) {
        return false
      }
    }
    // Reglas SSNN
    const rulesSSNN = everySSNN.reduce((sumSSNN, SSNN) => {
      sumSSNN[SSNN] = SSNN === 'whatsapp' ? { numberFormat } : { url }
      return sumSSNN
    }, {})

    // Todas las reglas
    const rules = {
      formFields: {
        email: {
          required
        },
        extraAddress: {},
        phone1: {
          required
        },
        phone2: {},
        place: {
          required
        },
        ...rulesSSNN
      }
    }

    return rules
  }
}
